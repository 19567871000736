.socialCard {
    width: fit-content;
    height: fit-content;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    padding: 25px 25px;
    gap: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}


@media screen and (max-width: 390px) {
    .socialCard {
        flex-direction: column;
        align-items: center;
    }

}

.socialContainer {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
}

/* facebook*/
.containerTwo:hover {
    background-color: #3b5998;
    transition-duration: .3s;
}

/* linkedin*/
.containerThree:hover {
    background-color: #0072b1;
    transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
    background-color: #128C7E;
    transition-duration: .3s;
}

.socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}