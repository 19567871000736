header {

    display: flex;
    background: #59c167;

    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    box-shadow: 0 0.5rem 4rem rgba(15, 15, 15, 0.747);
    /* border-radius: 0 0 40px 40px; */
}

header .container {
    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 90%;
    max-width: 1280px;

    margin: auto;
}

header img {
    width: 200px;
    margin-left: 20px;
    padding: 15px 0;
}

header nav ul {
    display: flex;
}

header nav ul li {
    list-style: none;
}

header nav ul li a {
    text-decoration: none;
    color: rgb(32, 30, 30);
    font-weight: bold;
    text-transform: uppercase;
    padding: 2.4rem;
    transition: all 250ms linear 0s;
}

header nav ul li a:hover {
    color: #e9780e;
    border-bottom: 1px solid #e9780e;
}