@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

section.projects {
    min-height: 100vh;
    display: flex;
    background-color: #2D3748;
    font-family: 'Montserrat', sans-serif;

}

section.projects .container {
    width: 90%;
    max-width: 1280px;
    margin: 30px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

section.projects h1 {
    color: #68D391;
    font-size: 4.8rem;
}

section.projects h3 {
    color: #F6AD55;
    text-align: center;
}

section.projects .galeria {
    width: 90%;
    max-width: 980px;
    margin: 15px auto;
    flex: 1;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



@media (max-width: 350px) {

    section.projects h1 {
        font-size: 2.4rem;
    }

    section.projects h3 {
        font-size: 1.4rem;
    }

}