footer {
    min-height: 5vh;
    display: flex;
    background-color: #171923;
    color: #EDF2F7;
    align-items: center;
    justify-content: center;
}

@media (max-width: 450px) {

    footer {
        font-size: 90%;
    }


}