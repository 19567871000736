@import url('https://fonts.googleapis.com/css2?family=Mynerve&display=swap');

section.contact {
    min-height: 50vh;
    display: flex;
    background: url("../../../public/fundo_contato.png") no-repeat;
    background-position: center;
    background-size: cover;

}

section.contact .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 90%;
    background: rgba(255, 255, 255, .05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    max-width: 980px;
    margin: 15px auto;
    padding: 20px;

}

section.contact .container h1 {
    font-family: 'Mynerve', cursive;
    color: #1A202C;
    font-size: 3.8rem;
    line-height: 4.6rem;
}