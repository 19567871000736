 section.hero {
     display: flex;
     flex-direction: column;
     height: 100vh;
     background: url("../../../public/fundo_hero.png") no-repeat;
     background-position: center;
     background-size: cover;

 }

 section.hero .container {
     width: 90%;
     max-width: 980px;

     margin: auto;

     display: flex;
     flex-direction: column;
     align-items: flex-end;
 }

 section.hero h2 {
     color: rgb(122, 199, 74);
     font-size: 4.8rem;
     line-height: 4.6rem;
 }

 section.hero p {
     color: rgb(230, 235, 227);
     font-size: 2.4rem;
     line-height: 3.2rem;
     margin: 10px 0px 0px;
 }


 @media (max-width: 630px) {

     .hero .container {
         flex-direction: column;
         text-align: center;
     }

     .hero .container img {
         order: -1;
     }


 }