section.aboutme {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

section.aboutme .container {
    width: 90%;
    max-width: 1480px;
    margin: auto;
    padding: 20px;
    display: flex;

    flex-direction: row;
    align-items: center;
}

section.aboutme .description {
    margin-right: 3rem;
    margin-top: 2rem;
    text-align: center;
}

section.aboutme .description h2 {
    color: rgb(75, 75, 75);
    font-size: 3.6rem;
    line-height: 4.6rem;
}

section.aboutme p {
    margin: 15px 0px 0px;
    text-align: justify;
}

section.aboutme .container img {
    float: right;
    max-width: 100%;
}

@media screen and (max-width: 930px) {
    section.aboutme .container {
        flex-direction: column;
        align-items: center;
    }

    section.aboutme .description {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    section.aboutme .container img {
        float: none;
    }
}