@import url('https://fonts.googleapis.com/css2?family=Mynerve&display=swap');

section.studio {
    display: flex;
    flex-direction: column;
}

section.studio .container {
    width: 90%;
    max-width: 1280px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section.studio .container h1 {
    font-family: 'Mynerve', cursive;
    color: #1A202C;
    font-size: 4.8rem;
    line-height: 4.6rem;
}

section.studio .container .services {
    max-width: 1280px;
    margin: 15px auto;
    flex: 1;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

section.studio .container .services .service {
    position: relative;
    width: 350px;
    height: 64vh;
    background-color: #f0eeedc5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 10px 10px 10px 10px #47a5afbd;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin: 20px;
    flex-direction: column;
    padding: 20px;
}

section.studio .container .services .service:hover {
    transform: scale(1.05);
    /* box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2); */
    box-shadow: 10px 10px 10px 10px #e9780e;

}

section.studio .container .services h4 {
    font-family: 'Mynerve', cursive;
    color: #e9780e;
    font-size: 2.8rem;
    line-height: 4.6rem;
    text-align: center;
    margin-bottom: 10px;

}

section.studio .container .services h5 {
    font-family: 'Mynerve', cursive;
    color: #1A202C;
    font-size: 2.2rem;
    text-align: center;
    color: #167923;
    margin: 5px;

}

section.studio .container .services p {
    text-align: justify;
    line-height: 1.6rem;
}

section.studio .container .services ul {
    align-self: flex-start;
}

section.studio .container .services li {
    list-style: none;
}

section.studio .iconList {
    vertical-align: bottom;
    font-size: 1.8rem;
    margin-right: 5px;
    color: #167923;
}


section.studio .container img {
    width: 50%;
    height: 50%;

}



@media (max-width: 500px) {
    section.studio .container .services .service {
        width: 95%;
        height: auto;
        font-size: 80%;
    }

    section.studio .container .services h4 {
        font-size: 2.2rem
    }

    section.studio .container h3 {
        line-height: 1.8rem;
    }


}

@media (max-width: 995px) {


    section.studio .container img {
        width: 90%;
        height: 90%;

    }

}